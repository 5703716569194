import React from 'react'


import { navigate } from "gatsby"
import { Stack, Typography } from '@mui/material';

import translateManager from "../translations/translateManager"


import Layout from '../components/Layout'
import AppDetail from '../components/AppDetail'

import { listVersions } from "../data"

export default function apps() {
    const item = listVersions.pointeuse;
    if(item.list == null || item.list.length == 0) {
        return null;
    }
    return (
        <Layout>
            <AppDetail appData={item} />

            <Stack 
                style={{margin: "1em 0"}}
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">

                <Typography variant="string" style={{margin: "4em 0 0 0", cursor: "pointer", fontFamily: "Lexand Deca bold", fontSize: "0.9em"}} component="div" onClick={() => { navigate("/")} }>{translateManager.getText("BACK_BTN")}</Typography>

            </Stack>
        </Layout>
    )
}
